/*
 * @Author: 冯杰
 * @Date: 2021-10-20 15:55:40
 * @LastEditTime: 2021-11-01 14:59:54
 * @FileName: 积分兑换订单明细
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import TableInfo from './table.js';

formCreate.component('TableInfo', TableInfo);

export default {
  extends: Form,
  components: {
    // 挂载组件
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'exchange_order_details',
      buttons: {
        submit: false,
        close: true,
        submitAudit: false,
      },
      fields: [
        'shipmentNum',
        'shipmentStatus',
        'externalId',
        'activityCode',
        'activityName',
        'shipmentTime',
        'finishTime',
        'shipmentProductName',
        'shipmentProductQuantity',
        'name',
        'phone',
        'address',
        'logisticsCode',
        'logisticsCompany',
      ],
    };
  },
  methods: {
    formComplete() {
      this.setValue({ ...this.formConfig.row });
      this.disabled(true, this.fields);
    },
    setRule(item) {
      const val = item;
      if (val.field === 'TableInfo') {
        val.props = {
          ...val.props,
          externalId: this.formConfig.row.externalId,
        };
      }
      return val;
    },

    // 提交
    submit() {},
  },
};
