/*
 * @Author: 冯杰
 * @Date: 2021-10-20 14:59:47
 * @LastEditTime: 2021-11-05 14:16:09
 * @FileName: 发货单列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';
import Form from './form/index.js';

export default {
  name: 'ExchangeOrderManagement',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/activity/exchange/findByConditions',
      // formConfig: {},
    };
  },
  components: {
    Modal,
    Form,
  },
  methods: {
    modalClick({ val, row }) {
      let urls = '';
      let text = '';
      if (val.code === 'particulars') {
        console.log('row', row);
        this.modalConfig.title = '详情';
        this.formName = 'Form';
        this.formConfig = { row };
        this.openFull();
      } else if (val.code === 'confirm_delivery') {
        urls = '/cps/activity/exchange/updateStatus';
        text = '发货';
        if (row.id) {
          this.selection.push(row.id);
        }
        const list = this.tableData;
        list.forEach((item) => {
          this.selection.forEach((v) => {
            if (v === item.id) {
              if (item.shipmentStatus === '1') {
                this.operationClick(urls, text);
              } else {
                return this.$message.error('未填写收获信息的待确认订单不允许发货');
              }
            }
          });
        });
      }
    },
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'shippingAddress') {
        rowData.formatter = ({ row }) => {
          const sheng = row.province ? row.province : '';
          const shi = row.city ? row.city : '';
          const qu = row.region ? row.region : '';
          const shippingAddress = sheng + shi + qu;
          return shippingAddress;
        };
      }
      return rowData;
    },
    afterGetList() {
      if (this.tableData.length > 0) {
        const newTableData = this.tableData.map((item) => ({
          ...item,
          shipmentProductQuantity: Number(item.shipmentProductQuantity),
        }));
        this.tableData = newTableData;
      }
    },
    // 确认发货操作
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.post(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('exchange_order_management');
  },
};
