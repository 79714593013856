/*
 * @Author: 冯杰
 * @Date: 2021-10-20 16:16:28
 * @LastEditTime: 2021-11-05 16:14:22
 * @FileName: 兑换信息表格
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';

export default {
  name: 'ExchangeInformation',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/activity/exchange/findByCodes',
      requestType: 'GET',
      params: { codes: this.externalId },
    };
  },
  components: {
    Modal,
  },
  props: {
    externalId: String,
  },
  methods: {
    afterGetList() {
      if (this.tableData.length > 0) {
        const newTableData = this.tableData.map((item) => ({
          ...item,
          shipmentProductQuantity: Number(item.shipmentProductQuantity),
        }));
        this.tableData = newTableData;
      }
    },
    setColumn(col) {
      const item = col;
      if (item.field === 'checkTypeseq') {
        item.title = '序号';
        item.width = '50';
      }
      return item;
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('exchange_information');
  },
};
